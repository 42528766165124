<template>
  <div class="container-fluid position-fixed bg-primary h-100">
    <div class="position-relative h-100">
      <div class="position-absolute top-50 start-50 translate-middle text-center">
        <img src="@/assets/images/app/logo-light.png" height="60" class="mb_custom animate__animated animate__bounceInDown">
        
      </div>
      <div class="bg-white bottom_cert">
          <div class="row justify-content-center">
              <div class="col-8 text-muted text-center">
                  <p class="font-size-12 text-muted mb-0 animate__animated animate__bounceInDown">Supervised, Registered and Certified by</p>
                  <img src="@/assets/images/app/certs.png" class="mb-4 img-fluid animate__animated animate__bounceInUp" alt="">
              </div>
          </div>
        </div>
    </div>
    <Lottie :path="'animate/lottie.json'" :title="'Loading'" :info="'Please wait...'" :show="showLottie" ref="lottieFunc" />
    <Common ref="commonFunc"/>
  </div>
</template>
<script>
import appConfig from "@/app.config";
import Lottie from "@/components/lottieView";
import Common from "@/components/common";
import { EncryptStorage } from 'encrypt-storage';
export const encryptStorage = new EncryptStorage('secret-key-value', {
  prefix: appConfig.prefix,
});
/**
 * Starter page
 */
export default {
  components: { 
    Lottie,
    Common
  },
  page: {
    title: "",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      showLottie:false,
      title: appConfig.title,
      items: [
        {
          text: appConfig.title,
          active: true,
        },
      ],
    };
  },
  mounted(){

  },
  created(){
    this.hideLanding()
    encryptStorage.clear();
    encryptStorage.setItem('lang', 'en');
  },
  methods:{
    hideLanding() {
      var self = this
      setTimeout(function(){  
        self.$router.push('/login')
      }, 1500)
      //sessionStorage.setItem("landing", true);
    }
  },
  middleware: "authentication",
};
</script>
<style scoped>
.bottom_cert{
  border-radius: 50%;
  position: fixed;
  bottom: -120px;
  width: 120%;
  left: -10%;
  padding: 30px 0px 110px 0px;
  overflow: hidden;
}
.mb_custom{
  margin-bottom:150px;
}
</style>